import React, { useState } from 'react';
import axios from 'axios';
import './UpdateExistentPR.css'; // Import CSS for styling
import Dialog from './Dialog'; // Import the Dialog component

const UpdateExistentPR = ({ existingPRs, token, selectedRepo }) => {
    const [selectedPR, setSelectedPR] = useState(null);
    const [combinedSuggestions, setCombinedSuggestions] = useState([]);
    const [taskDescription, setTaskDescription] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [loading, setLoading] = useState(false); // State for loading
    const [dialogVisible, setDialogVisible] = useState(false); // State for dialog visibility
    const [prUrl, setPrUrl] = useState(''); // State to hold PR URL
    const [version, setVersion] = useState('v1'); // State to track selected version

    const handlePRSelection = async (prNumber) => {
        setSelectedPR(prNumber);
        if (!prNumber) return;

        try {
            const { data } = await axios.post(
                '/api/pr/suggestions',
                { repo_name: selectedRepo, pr_number: prNumber },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setCombinedSuggestions(data.suggestions || []);
            setTaskDescription(data.task_prompt || '');
        } catch (error) {
            alert(`Failed to fetch PR suggestions: ${error.response?.data?.msg || error.message}`);
        }
    };

    const handleTaskSubmit = async () => {
        setLoading(true);  // Show loading spinner
        try {
            const endpoint = version === 'v1' ? '/api/tasks/update-pr/v1' : '/api/tasks/update-pr/v2'; // Choose version based on selection
            const { data } = await axios.post(
                endpoint,
                {
                    repo_name: selectedRepo,
                    pr_number: selectedPR,  // Pass the PR number
                    task: `${taskDescription}\n\nAdditional Information: ${additionalInfo}`,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setPrUrl(data.pr_url); // Set the PR URL
            setDialogVisible(true); // Show dialog
        } catch (error) {
            alert(`Failed to update pull request: ${error.response?.data?.msg || error.message}`);
        } finally {
            setLoading(false);  // Hide loading spinner after request
        }
    };

    const handleDialogClose = (visit) => {
        if (visit && prUrl) {
            window.open(prUrl, '_blank'); // Open PR URL in new tab
        }
        setDialogVisible(false); // Close dialog
    };

    return (
        <div className="update-pr-container">
            <h2 className="update-pr-heading">Update Existing Pull Request</h2>
            
            {/* Version selection */}
            <div className="version-selection">
                <label>
                    <input 
                        type="radio" 
                        value="v1" 
                        checked={version === 'v1'} 
                        onChange={() => setVersion('v1')} 
                    />
                    Best for simple projects
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="v2" 
                        checked={version === 'v2'} 
                        onChange={() => setVersion('v2')} 
                    />
                    Best for complex projects
                </label>
            </div>

            <div className="form-group">
                <label className="form-label">Select an Existing PR</label>
                <select
                    className="form-select"
                    onChange={(e) => handlePRSelection(e.target.value)}
                    value={selectedPR || ''}
                >
                    <option value="">Select PR</option>
                    {existingPRs.map((pr) => (
                        <option key={pr.number} value={pr.number}>
                            {pr.title}
                        </option>
                    ))}
                </select>
            </div>

            {selectedPR && (
                <>
                    <h3 className="suggestions-heading">PR Suggestions</h3>
                    {combinedSuggestions.length > 0 ? (
                        <ul className="suggestions-list">
                            {combinedSuggestions.map((suggestion, index) => (
                                <li key={index} className="suggestion-item">{suggestion}</li>
                            ))}
                        </ul>
                    ) : (
                        <p className="no-suggestions">No suggestions available.</p>
                    )}

                    <h3 className="task-prompt-heading">Task Prompt</h3>
                    <p className="task-prompt-text">{taskDescription || 'No task prompt available.'}</p>

                    <textarea
                        className="task-textarea"
                        placeholder="Additional Information"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        style={{ resize: 'vertical', minHeight: '100px' }} // Allow vertical resize
                    />

                    {!loading ? (
                        <button className="submit-btn" onClick={handleTaskSubmit} disabled={!taskDescription}>
                            Submit Task
                        </button>
                    ) : (
                        <button className="loading-btn" disabled>
                            Loading...
                        </button>
                    )}
                </>
            )}

            {dialogVisible && (
                <Dialog 
                    title="PR Update Successful" 
                    message="Do you want to visit the pull request?" 
                    onClose={handleDialogClose} 
                />
            )}
        </div>
    );
};

export default UpdateExistentPR;
