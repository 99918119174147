import React from 'react';
import './Dialog.css';  // Import CSS for styling

const Dialog = ({ message, onClose }) => {
    const handleClose = (shouldVisit) => {
        onClose(shouldVisit);  // Call the onClose function with true or false based on user's decision
    };

    return (
        <div className="dialog-backdrop">
            <div className="dialog-box">
                <p>{message}</p>
                <div className="dialog-buttons">
                    <button className="dialog-btn" onClick={() => handleClose(true)}>Yes</button>
                    <button className="dialog-btn" onClick={() => handleClose(false)}>No</button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
