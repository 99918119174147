import React from 'react';

const PRButtons = ({ showTaskManager, showPRManager }) => {
  return (
    <div className="button-group">
      <button className="pr-button" onClick={showPRManager}>
        Review PR
      </button>
      <button className="pr-button" onClick={showTaskManager}>
        Code PR
      </button>
    </div>
  );
};

export default PRButtons;
