import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if access tokens are in the URL after GitHub OAuth login
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('access_token');
        const githubToken = urlParams.get('github_token');

        if (accessToken && githubToken) {
            // Save tokens in localStorage or sessionStorage
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('github_token', githubToken);

            // Redirect user to the dashboard
            navigate('/dashboard');  // Redirect to dashboard after storing tokens
        }
    }, [navigate]);

    const handleGitHubLogin = () => {
        // Redirect to GitHub OAuth endpoint
        window.location.href = `${API_BASE_URL}/api/auth/github`;
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
        if (!rememberMe) {
            localStorage.setItem('rememberMe', true); // Save remember me option
        } else {
            localStorage.removeItem('rememberMe'); // Remove remember me option
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Your_AI_Developer</h1>
            <div style={styles.loginBox}>
                <button style={styles.githubButton} onClick={handleGitHubLogin}>
                    Login with GitHub
                </button>
                <div style={styles.rememberMeBox}>
                    <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                    />
                    <label style={styles.label}>Remember me</label>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '300px',
    },
    githubButton: {
        padding: '10px 20px',
        fontSize: '1.1rem',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    rememberMeBox: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        marginLeft: '10px',
    },
};

export default Login;
