import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RepositoryManager.css'; // Import CSS file for styling

const RepositoryManager = ({ token, onSelectRepo }) => {
    const [repositories, setRepositories] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState('');

    useEffect(() => {
        const fetchRepositories = async () => {
            try {
                const response = await axios.get('/api/repositories', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setRepositories(response.data);
            } catch (error) {
                alert('Failed to fetch repositories');
            }
        };

        fetchRepositories();
    }, [token]);

    const handleRepoChange = (e) => {
        const repo = e.target.value;
        setSelectedRepo(repo);
        onSelectRepo(repo);
    };

    return (
        <div className="repo-container">
            <h2 className="repo-heading">Select a Repository</h2>
            <div className="repo-select-box">
                <select className="repo-select" onChange={handleRepoChange} value={selectedRepo}>
                    <option value="">Select Repository</option>
                    {repositories.map((repo) => (
                        <option key={repo} value={repo}>
                            {repo}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default RepositoryManager;
