import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard'; // Import the new Dashboard component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Login Route */}
        <Route path="/login" element={<Login />} />

        {/* Dashboard Route */}
        <Route
          path="/dashboard"
          element={
            localStorage.getItem('access_token') ? (
              <Dashboard /> // Render the Dashboard component
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Redirect from any unknown routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
