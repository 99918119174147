import React, { useState } from 'react';
import axios from 'axios';
import './CreateNewPR.css'; // Import CSS for styling
import Dialog from './Dialog'; // Import the Dialog component

const CreateNewPR = ({ branches, token, selectedRepo }) => {
    const [selectedBranch, setSelectedBranch] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [customBranchName, setCustomBranchName] = useState('');
    const [loading, setLoading] = useState(false); // State for loading
    const [dialogOpen, setDialogOpen] = useState(false); // State for dialog visibility
    const [prUrl, setPrUrl] = useState(''); // State for PR URL
    const [version, setVersion] = useState('v1'); // State for version selection

    const handleTaskSubmit = async () => {
        setLoading(true);  // Show loading spinner
        try {
            const endpoint = version === 'v1' ? '/api/tasks/new-pr/v1' : '/api/tasks/new-pr/v2'; // Choose the correct endpoint based on version
            const response = await axios.post(endpoint, {
                repo_name: selectedRepo,
                branch_name: selectedBranch,
                task: taskDescription,
                custom_branch_name: customBranchName,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            const url = response.data.pr_url;  // Get the PR URL from the response
            setPrUrl(url);
            setDialogOpen(true); // Open the dialog
        } catch (error) {
            alert('Failed to create pull request');
        } finally {
            setLoading(false);  // Hide loading spinner after request
        }
    };

    const handleDialogClose = (shouldVisit) => {
        setDialogOpen(false);
        if (shouldVisit) {
            window.open(prUrl, '_blank'); // Open PR URL in a new tab
        }
    };

    return (
        <div className="new-pr-container">
            <h2 className="new-pr-heading">Create New Pull Request</h2>
            
            {/* Version selection */}
            <div className="version-selection">
                <label>
                    <input 
                        type="radio" 
                        value="v1" 
                        checked={version === 'v1'} 
                        onChange={() => setVersion('v1')} 
                    />
                    Best for simple projects
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="v2" 
                        checked={version === 'v2'} 
                        onChange={() => setVersion('v2')} 
                    />
                    Best for complex projects
                </label>
            </div>

            <div className="form-group">
                <label className="form-label">Select Source Branch</label>
                <select 
                    className="form-select" 
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    value={selectedBranch}
                >
                    <option value="">Select Branch</option>
                    {branches.map((branch) => (
                        <option key={branch} value={branch}>
                            {branch}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label className="form-label">Task Description</label>
                <textarea
                    className="form-textarea"
                    placeholder="Enter task description"
                    value={taskDescription}
                    onChange={(e) => setTaskDescription(e.target.value)}
                    rows="4"
                />
            </div>

            <div className="form-group">
                <label className="form-label">New Branch Name</label>
                <input
                    type="text"
                    className="form-input"
                    placeholder="Enter custom branch name"
                    value={customBranchName}
                    onChange={(e) => setCustomBranchName(e.target.value)}
                />
            </div>

            {!loading ? (
                <button 
                    className="submit-btn" 
                    onClick={handleTaskSubmit} 
                    disabled={!selectedBranch || !taskDescription}
                >
                    Submit Task
                </button>
            ) : (
                <button className="loading-btn" disabled>
                    Loading...
                </button>
            )}

            {dialogOpen && (
                <Dialog 
                    message="Pull request created successfully! Do you want to visit it?" 
                    onClose={handleDialogClose} 
                />
            )}
        </div>
    );
};

export default CreateNewPR;
