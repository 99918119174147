import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CreateNewPR from './CreateNewPR'; // Child component for creating new PR
import UpdateExistentPR from './UpdateExistentPR'; // Child component for updating existing PR
import './TaskManager.css'; // Import CSS file for styling

const TaskManager = ({ token, selectedRepo }) => {
    const [branches, setBranches] = useState([]);
    const [existingPRs, setExistingPRs] = useState([]);
    const [isCreatingNewPR, setIsCreatingNewPR] = useState(false);
    const [isSelectingExistingPR, setIsSelectingExistingPR] = useState(false);

    const navigate = useNavigate();

    // Redirect to login if no token
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    // Fetch branches and pull requests
    useEffect(() => {
        if (selectedRepo) {
            fetchBranches();
            fetchPRs();
        }
    }, [selectedRepo]);

    const fetchBranches = async () => {
        try {
            const response = await axios.post('/api/repositories/branches', { repo_name: selectedRepo }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBranches(response.data);
        } catch (error) {
            alert('Failed to fetch branches');
        }
    };

    const fetchPRs = async () => {
        try {
            const response = await axios.post('/api/pr/check', { repo_name: selectedRepo }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setExistingPRs(response.data);
        } catch (error) {
            alert('Failed to fetch pull requests');
        }
    };

    const handleNewPRClick = () => {
        setIsCreatingNewPR(true);
        setIsSelectingExistingPR(false);
    };

    const handleExistingPRClick = () => {
        setIsCreatingNewPR(false);
        setIsSelectingExistingPR(true);
    };

    return (
        <div className="task-manager-container">
            <h2 className="task-manager-heading">Manage Pull Requests</h2>
            <div className="button-group">
                <button className={`task-button ${isCreatingNewPR ? 'active' : ''}`} onClick={handleNewPRClick}>
                    Create New PR
                </button>
                <button className={`task-button ${isSelectingExistingPR ? 'active' : ''}`} onClick={handleExistingPRClick}>
                    Update Existing PR
                </button>
            </div>

            {isCreatingNewPR && (
                <CreateNewPR
                    branches={branches}
                    token={token}
                    selectedRepo={selectedRepo}
                />
            )}

            {isSelectingExistingPR && (
                <UpdateExistentPR
                    existingPRs={existingPRs}
                    token={token}
                    selectedRepo={selectedRepo}
                />
            )}
        </div>
    );
};

export default TaskManager;
