import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PRManager.css'; // Importing the CSS file

const PRManager = ({ token, selectedRepo, onEnableTaskManager }) => {
  const [taskManagerEnabled, setTaskManagerEnabled] = useState(false);
  const [pullRequests, setPullRequests] = useState([]);
  const [selectedPR, setSelectedPR] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingReview, setLoadingReview] = useState(false); // Loading state for PR review

  // Fetch the list of PRs when the component mounts
  useEffect(() => {
    const fetchPRs = async () => {
      try {
        const response = await axios.post('/api/pr/check', { repo_name: selectedRepo }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        setPullRequests(response.data);
        setLoading(false);
      } catch (error) {
        alert(`Error fetching PRs: ${error.response?.data?.msg || error.message}`);
        setLoading(false);
      }
    };

    fetchPRs();
  }, [selectedRepo, token]);

  const handleReviewPR = async () => {
    if (!selectedPR) {
      alert('Please select a PR to review.');
      return;
    }

    setLoadingReview(true); // Start loading spinner for review

    try {
      await axios.post('/api/review-pr', {
        repo_name: selectedRepo,
        pr_number: selectedPR,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('PR reviewed successfully!');
    } catch (error) {
      alert(`Error reviewing PR: ${error.response?.data?.msg || error.message}`);
    } finally {
      setLoadingReview(false); // Stop loading spinner for review
    }
  };

  return (
    <div className="pr-manager-container">
      <h2 className="pr-manager-heading">PR Manager</h2>

      {/* Show loading spinner while fetching PRs */}
      {loading ? (
        <p>Loading PRs...</p>
      ) : (
        <div className="form-group">
          <label htmlFor="pr-select">Select a Pull Request to review:</label>
          <select
            id="pr-select"
            value={selectedPR || ''}
            onChange={(e) => setSelectedPR(e.target.value)}
            className="form-select"
          >
            <option value="">Select PR</option>
            {pullRequests.map((pr) => (
              <option key={pr.number} value={pr.number}>
                {pr.title} (#{pr.number})
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Conditionally render the button or loading spinner */}
      <button className="pr-button" onClick={handleReviewPR} disabled={!selectedPR || loadingReview}>
        {loadingReview ? 'Loading...' : 'Start Review PR'}
      </button>
    </div>
  );
};

export default PRManager;
